/**
 * 用于重置输入框位置的指令
 */

let $input;
const debouncedResizeHandler = debounce(onResize, 100);
const scrollByDistance = debounce(rawScroll, 100);

function install(Vue) {
    Vue.directive('position', {
        bind(el) {
            window.addEventListener('resize', debouncedResizeHandler);
            if (el) {
                el.addEventListener('focus', onFocus);
                el.addEventListener('blur', onBlur);
            }
        },
        unbind(el) {
            window.removeEventListener('resize', debouncedResizeHandler);
            if (el) {
                el.removeEventListener('focus', onFocus);
                el.removeEventListener('blur', onBlur);
            }
        },
    });
}

function onResize() {
    centerV($input);
}

function centerV(el) {
    if (!el || !el.getBoundingClientRect) return;

    const { top, height } = el.getBoundingClientRect();
    const winH = window.innerHeight;
    const targetTop = (winH - height) / 2;
    const diffTop = top - targetTop;

    scrollByDistance(diffTop);
}

function rawScroll(diff) {
    window.scrollBy({
        top: diff,
        behavior: 'smooth',
    });
}

function onFocus(e) {
    if (e.target) {
        $input = e.target;
    }
}

function onBlur() {
    $input = null;
    scrollByDistance(-1);
}

function debounce(func, delay) {
    let timer;

    return function(...params) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...params);
        }, delay);
    };
}

export default {
    install,
};
