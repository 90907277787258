/**
 * 判定数据对象是否合格
 * @param {object} params 待测试的数据对象
 * @param {Array} cases 检测条件
 */
export function checkValid(params, cases) {
    if (!params || !cases || cases.length <= 0) {
        throw new Error('Invalid arguments');
    }

    for (let i = 0; i < cases.length; i++) {
        const {
            key,
            required,
        } = cases[i];

        const val = params[key];
        if (checkSingleCase(params, cases[i]) === false) {
            if (required && !val) {
                return {
                    valid: false,
                    msg: cases[i].sky_msg,
                }
            }
            return {
                valid: false,
                msg: cases[i].msg,
            }
        }
    }

    return {
        valid: true,
    };
}

function checkSingleCase(params, thecase) {
    const {
        key,
        required,
        phone,
        cardno,
        realName
    } = thecase;

    const val = params[key];
    if (required && !val) return false;
    if (realName && !checkUserName(val)) return false;
    if (phone && !checkPhone(val)) return false;
    if (cardno && !checkID(val)) return false;

    return true;
}

export function checkPhone(val) {
    const PHONE = /^1\d{10}$/;
    return PHONE.test(val);
}

export function checkUserName(val) {
    const PHONE = /^[\u4E00-\uFA29]{2,20}|[a-zA-Z0-9_\-\.\—]{2,20}$/;
    return PHONE.test(val);
}

/**
 * 校验身份证是否正确
 * @param {string} id
 * @returns {boolean}
 */
 function checkID(id) {
    if (!id) return false;
    if (typeof id !== 'string') return false;
    if (id.length !== 18) return false;

     //  const ID_REG = /^\d{18}|\d{17}x$/i;
     const ID_REG = /^[0-9a-z]+$/i;

    return ID_REG.test(id);
}
