
import { mmcAjax } from '@/common/js/mmcModule';
const baseurl = 'https://api.jisuchou.net/';

/**
 * 检测绿通可激活剩余次数
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93463371
 * @param
 **/
export function checkGreen() {
    const url = `${baseurl}mmc/green/surptimes`
    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

/**
 * 获取短信
 * @param {object} params
 * @param {string} phone 手机号
 * @param {string} country_code 国家标识
 * @param {string} verify_code_scene 渠道标识
 * @param {string} auth_key 获取验证码标识 jishihelp_h5
 **/

export function getMsgCode(phone) {
    const url = `${baseurl}mmc/passport/sms/send`
    return mmcAjax({
        method: 'POST',
        url,
        crossDomain: true,
        data: {
            phone,
            verify_code_scene: 'green_active',
            country_code: 'CN',
            auth_key: 'jishihelp_h5'
        }
    });
}

/**
 * 绿通激活
 * @param {object} params
 * @param {number} relation 关系
 * @param {string} real_name 真实姓名
 * @param {string} id_card 身份证号
 * @param {string} phone 手机号
 * @param {string} country_code CN
 * @param {string} mss_code 验证码

 **/
export function postGreenActivate(params) {
    const url = `${baseurl}mmc/green/activate`
    return mmcAjax({
        method: 'POST',
        url,
        crossDomain: true,
        data: params
    })
}
