<template>
    <div v-if="checkLoad" class="green-channel">
        <span class="tips">
            恭喜你加入绿色就医通道服务健康守护计划，立刻完善信息，即可激活！
        </span>

        <div class="form-warpper">
            <div class="item-group flex-row line item-relation">
                <label class="label">权益人使用人与本人关系</label>
                <span class="select-value">{{changeSelectVal}}</span>
                <div class="select-container">
                    <select
                        v-model="selectVal"
                        class="select-group-content"
                        @change="changeOption">
                        <option
                            v-for="item in relativeList"
                            :value="item.name"
                            :key="item.name">{{item.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="item-group flex-column line">
                <label for="name" class="label">真实姓名</label>
                <input
                    id="name"
                    v-model="name"
                    type="text"
                    maxlength="20"
                    minlength="2"
                    placeholder="填写权益人真实姓名"
                />
            </div>

            <div class="item-group flex-column line">
                <label for="cardno" class="label">身份证号码</label>
                <input
                    id="cardno"
                    v-model="cardno"
                    maxlength="18"
                    type="text"
                    placeholder="填写权益人身份证号码" />
            </div>

            <div class="item-group flex-column line">
                <label for="phone" class="label">手机号</label>
                <input
                    v-position
                    type="tel"
                    id="phone"
                    v-model="phone"
                    maxlength="11"
                    placeholder="请输入权益人的手机号"
                />
            </div>

            <div class="item-group flex-column line">
                <div class="flex-row cell-mscode">
                    <label for="code" class="label">验证码</label>

                    <div class="code-msg"
                        @click="sendmMsgCode">
                        <span class="green" v-if="!startCountDown">{{sendMsgText}}</span>
                        <span class="grey" v-else>{{time}}s后重新获取</span>
                    </div>
                </div>

                <input
                    v-position
                    id="code"
                    v-model="code"
                    type="tel"
                    placeholder="填写验证码"
                    maxlength="4"
                />
            </div>
            <button class="active-btn" @click="onSubmit">立即激活</button>
        </div>

        <div v-if="activeInfo && !activeInfo.times" class="not-activated" @touchmove.prevent >
            <span class="desc" v-if="activeCheckTips">{{activeCheckTips}}</span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';
import { checkGreen, getMsgCode, postGreenActivate } from './js/api.js';
import { checkValid, checkPhone } from './js/checkValid.js';

import positionDirective from './js/directive.position.js';
Vue.use(positionDirective);

const relativeList = [
    {   name: '本人',
        value: 1
    },
    {   name: '父母',
        value: 2
    },
    {   name: '配偶',
        value: 3
    },
    {   name: '子女',
        value: 4
    },
    {   name: '其他',
        value: 5
    }
];
export default {
    name:'GreenChannel',
    data () {
        return {
            relativeList,
            selectId: 1,
            selectVal: relativeList[0].name,
            name: '',
            cardno: '',
            phone: '',
            code: '',
            country_code: 'CN',

            submitting: false,
            time: 60,
            sendMsgText: '发送验证码',
            isSendMsg: false,
            startCountDown: false,

            activeInfo: null,
            checkLoad: false
        }
    },

    computed: {
        changeSelectVal() {
            if(this.selectVal) return this.selectVal;
            return "请选择"
        },
        submitData() {
            return {
                real_name: this.name.trim(),
                id_card: this.cardno.trim(),
                phone: this.phone.trim(),
                sms_code: this.code.trim(),
                relation: this.selectId,
                country_code: this.country_code,
                auth_key: 'jishihelp_h5',
            };
        },

        activeCheckTips() {
            const { bought } = this.activeInfo;
            if (bought) {
                return '您的剩余激活次数为0，无法激活！';
            }
            return '您暂未购买该服务，无法激活！';
        }
    },

    created() {
        authChecker.check({ notneedphone: 1 }, () => {
            showLoading('加载中...');
            this.checkUserGreen();

            shareCtrl.init();
        });
    },

    methods: {
        onSubmit() {
            const that = this;
            if (this.submitting) return;
            if (this.checkValid() === false) return;
            this.submitting = true;

            postGreenActivate(this.submitData)
            .then(res => {
                this.submitting = false;

                if(res.code === 0) {
                    alertMsg('激活成功', 2000);
                    setTimeout(() => {
                        this.$router.push({
                            path: '/fund/greenchannel/success'
                        });
                    }, 2000);
                }
            });
        },

        checkUserGreen() {
            checkGreen().then(res => {
                hideLoading();
                if (res.code === 0) {
                    this.checkLoad = true;
                    this.activeInfo = res.data;
                } else {
                    alertMsg(res.msg);
                }
            }).catch(() => {
                hideLoading();
            })
        },

        sendmMsgCode() {
            if(this.isSendMsg) return;
            if(this.startCountDown) return;

            const phone = this.phone.trim();
             if(!phone) {
                alertMsg('请输入权益人手机号');
                return;
            }
            if(!checkPhone(phone)) {
                alertMsg('请输入正确的权益人手机号');
                return;
            }
            this.isSendMsg = true;

            getMsgCode(phone)
            .then(res => {
                this.isSendMsg = false;
                if (res && res.code === 0) {
                    alertMsg('短信发送成功');
                    this.startCountDown = true;
                    this.countDownTime();
                    return;
                }
                alertMsg(res.msg);

            }).catch(err => {
                this.isSendMsg = false;
            })
        },

        countDownTime() {
            let countDownIntervla = setInterval(() => {
                let time = this.time;
                if (time <= 1) {
                    this.startCountDown = false;
                    this.isSendMsg = false;
                    this.time = 60;
                    clearInterval(countDownIntervla);
                    return
                }
                else {
                    this.time = --time;
                }
            }, 1000)
        },

        checkValid() {
            const cases = [
                {
                    key: 'real_name',
                    sky_msg: '请输入权益人真实姓名',
                    msg: '请输入正确的权益人真实姓名',
                    realName: true,
                    required: true
                },
                {
                    key: 'id_card',
                    sky_msg: '请输入权益人身份证号码',
                    msg: '请输入正确的权益人身份证号码',
                    cardno: true,
                    required: true
                },
                {
                    key: 'phone',
                    sky_msg: '请输入权益人手机号',
                    msg: '请输入正确的权益人手机号',
                    phone: true,
                    required: true
                },
                {
                    key: 'sms_code',
                    sky_msg: '请填写短信验证码',
                    required: true
                }
            ];

            const result = checkValid(this.submitData, cases);
            if (result.valid === false) {
                alertMsg(result.msg);
                return false;
            }
            return true;
        },

        changeOption(event) {
            const relationVal = event.target.value;
            this.selectVal = relationVal;

            relativeList.map(item => {
                if(item.name === relationVal) {
                    this.selectId = item.value;
                }
            });
        },
    }
}
</script>

<style lang='less' scoped>
.green-channel {
    padding: 15px 20px 60px;
    .tips {
        display: block;
        padding: 0 35px;
        margin-bottom: 5px;
        font-size: 15px;
        color: #9B9B9B;
        text-align: center;
        line-height: 30px;
    }
    .modal-content {
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .flex-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .flex-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .line::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #E9E9E9;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .item-relation {
        padding: 25px 0;
    }
    input {
        padding: 20px 0 24px;
        width: 100%;
        border: none;
        font-size: 16px;
        color: #333333;
        line-height: 1;
        -webkit-box-shadow: 0 0 0 1000px white inset;
    }
}

.item-group {
    position: relative;
    padding-top: 24px;
    .label {
        font-size:17px;
        color: #333333;
        letter-spacing: 0;
        line-height: 1;
        font-weight: 500;
    }
    .label-mr-bt-20 {
        margin-bottom: 20px;
    }
}

.cell-mscode {
    width: 100%;
    position: relative;
    .code-msg {
        border:none;
        position: absolute;
        right: 0;
        z-index: 2;
    }

    span {
        font-size: 14px;
        text-align: center;
        line-height: 1;
        padding: 10px 15px;
        border-radius: 4px;
    }

    .green {
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        color: #FFFFFF;
    }

    .gary {
        background-image: #ccc;
        box-shadow: none;
        color: #FFFFFF;
    }
}

.select-value {
    color: #333;
    line-height: 1;
    font-size: 17px;
    margin-top: -1px;
    position: relative;
    padding-right: 20px;
    display: block;
    &::after {
        content: "";
        display: block;
        width: 16px;
        height: 14px;
        background: url('./img/arrow-bottom.png') no-repeat center center;
        background-size: 70%;
        position: absolute;
        right: 0;
        top: 3px;
    }
}

.select-container{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    flex: 1;
    width: 100%;
    z-index: 2;
    opacity: 0;
}

.select-group-content {
    width: 100%;
    height: 36px;
    background: #fff;
    color: #333;
}

.active-btn {
    border: none;
    background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
    box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
    border-radius: 4px;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    margin-top: 60px;
    padding: 15px 0;
    width: 100%;
}

.not-activated {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
    .desc {
        display: block;
        width: 80%;
        background: #FFFFFF;
        border-radius: 5px;
        padding: 16px 18px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}
</style>
